const state = () => {
  return {
    menu: [
      {
        icon: 'LayersIcon',
        pageName: 'side-menu-dashboard',
        title: 'Справочники',
        subMenu: [
          {
            pageName: 'OrganisationType',
            title: 'Типы организаций',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'Status',
            title: 'Статусы',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'Otrasl',
            title: 'Отрасли',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'OrgPravs',
            title: 'Организационно правовые формы',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'FormaSobst',
            title: 'Формы собственности',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'Sostoyanies',
            title: 'Состояния',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'SostoyanieAutos',
            title: 'Состояния авто',
            permission: ['DICT_READ'],
          },
          {
            pageName: 'side-menu-dashboard',
            title: 'Модуль "Планирование"',
            subMenu: [
              {
                pageName: 'ZakupGroup',
                permission: ['DICT_READ'],
                title: 'Группы закупок'
              },
              {
                pageName: 'ZakupStatia',
                permission: ['DICT_READ'],
                title: 'Статьи закупок'
              },
              {
                pageName: 'RashodGroup',
                permission: ['DICT_READ'],
                title: 'Группы расходов'
              },
              {
                pageName: 'RashodStatia',
                permission: ['DICT_READ'],
                title: 'Статьи расходов'
              },
              {
                pageName: 'DohodGroup',
                permission: ['DICT_READ'],
                title: 'Группы ист. дох.'
              },
              {
                pageName: 'DohodIst',
                permission: ['DICT_READ'],
                title: 'Источники дох.'
              }
            ]
          },
        ]
      },
      {
        icon: 'HomeIcon',
        pageName: 'OrganisationList',
        title: 'Организации',
        permission: ['ORG_READ']
      },
      {
        icon: 'MapIcon',
        pageName: 'MapOrganisations',
        title: 'Карта имуществ',
        permission: ['MAP_READ']
      },
      {
        icon: 'FileTextIcon',
        pageName: 'side-menu-dashboard',
        title: 'Проекты',
        subMenu: [
          {
            pageName: 'Projects',
            title: 'Проекты',
            permission: ['PROJECT_READ']
          },
          {
            pageName: 'ProjectsOnRealisation',
            title: 'На реализации',
            permission: ['PROJECT_ONREALIZATION_READ']
          }
        ]
      },
      {
        icon: 'CheckSquareIcon',
        pageName: 'side-menu-dashboard',
        title: 'Планирование',
        subMenu: [
          {
            pageName: 'PlanMain',
            title: 'План ПЭР',
            permission: ['PER_READ']
          },
          // {
          //   pageName: 'DohodMain',
          //   title: 'Доходы',
          //   permission: ['PROJECT_READ']
          // },
          {
            pageName: 'RashodMain',
            title: 'Расходы',
            permission: ['RASHOD_READ']
          },
          {
            pageName: 'ZakupkaMain',
            title: 'Закупки',
            permission: ['ZAKUPKA_READ']
          },
        ]
      },
      {
        icon: 'SettingsIcon',
        pageName: 'side-menu-dashboard',
        title: 'Администрирование',
        subMenu: [
          {
            pageName: 'AccessControl',
            title: 'Контроль доступов',
            permission: ['SUPER_ADMIN']
          },
          {
            pageName: 'UserRole',
            title: 'Роли',
            permission: ['SUPER_ADMIN']
          },
          {
            pageName: 'Users',
            title: 'Пользователи',
            permission: ['USERS_READ']
          }
        ]
      }
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
