import $api from '@/http'

async function getRegions() {
  return await $api.get('/Oblasts/')
}

async function getCountries() {
  return await $api.get('/Country')
}

export default {
  getRegions,
  getCountries
}
