import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layouts/top-menu/Main')
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: () => import('@/layouts/side-menu/Main'),
    children: [
      {
        path: 'accessControl',
        name: 'AccessControl',
        permission: ['SUPER_ADMIN'],
        component: () => import('@/views/Users/accessControl/Main')
      },
      {
        path: 'userRole',
        name: 'UserRole',
        permission: ['SUPER_ADMIN'],
        component: () => import('@/views/Users/userRole/Main')
      },
      {
        path: 'organisationType',
        name: 'OrganisationType',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/organisationType/Main')
      },
      {
        path: 'status',
        name: 'Status',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/status/Main')
      },
      {
        path: 'otrasl',
        name: 'Otrasl',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/otrasl/Main')
      },
      {
        path: 'orgPravs',
        name: 'OrgPravs',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/orgPrav/Main')
      },
      {
        path: 'formaSobst',
        name: 'FormaSobst',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/formaSobst/Main')
      },
      {
        path: 'sostoyanies',
        name: 'Sostoyanies',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/sostoyanies/Main')
      },
      {
        path: 'sostoyanieAutos',
        name: 'SostoyanieAutos',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/sostoyanieAutos/Main')
      },
      {
        path: 'organisationList',
        name: 'OrganisationList',
        permission: ['ORG_READ'],
        component: () => import('@/views/Module1_Imushestvo/Organisation/MainList.vue')
      },
      {
        path: 'organisationAdd',
        name: 'OrganisationAdd',
        permission: ['ORG_ADD'],
        component: () => import('@/views/Module1_Imushestvo/Organisation/MainAdd.vue')
      },
      {
        path: 'organisationEdit',
        name: 'OrganisationEdit',
        permission: ['ORG_EDIT'],
        component: () => import('@/views/Module1_Imushestvo/Organisation/MainEdit.vue')
      },
      {
        path: 'imushestvaAdd',
        name: 'ImushestvaAdd',
        permission: ['ORG_NEDVIJ_READ'],
        component: () => import('@/views/Module1_Imushestvo/Imushestva/MainAdd.vue')
      },
      {
        path: 'imushestvaEdit',
        name: 'ImushestvaEdit',
        permission: ['ORG_NEDVIJ_EDIT'],
        component: () => import('@/views/Module1_Imushestvo/Imushestva/MainEdit.vue')
      },
      {
        path: 'zemlyaAdd',
        name: 'ZemlyaAdd',
        permission: ['ORG_ZEMLYA_READ'],
        component: () => import('@/views/Module1_Imushestvo/Zemlya/MainAdd.vue')
      },
      {
        path: 'zemlyaEdit',
        name: 'ZemlyaEdit',
        permission: ['ORG_ZEMLYA_EDIT'],
        component: () => import('@/views/Module1_Imushestvo/Zemlya/MainEdit.vue')
      },
      {
        path: 'users',
        name: 'Users',
        permission: ['USERS_READ'],
        component: () => import('@/views/Users/users/Main.vue')
      },
      {
        path: 'mapOrganisations',
        name: 'MapOrganisations',
        permission: ['MAP_READ'],
        component: () => import('@/views/Module1_Imushestvo/MapOrganisation/Main.vue')
      },
      {
        path: 'mapOrganisationsInfo',
        name: 'MapOrganisationsInfo',
        permission: ['MAP_READ'],
        component: () => import('@/views/Module1_Imushestvo/MapOrganisation/MainInfo.vue')
      },
      {
        path: 'mapZemlyaInfo/:id',
        name: 'MapZemlyaInfo',
        permission: ['MAP_READ'],
        component: () => import('@/views/Module1_Imushestvo/MapOrganisation/InfoZemlya.vue')
      },
      {
        path: 'projects',
        name: 'Projects',
        permission: ['PROJECT_READ'],
        component: () => import('@/views/Module2_Proekty/projects/MainList.vue')
      },
      {
        path: 'projectsOnRealisation',
        name: 'ProjectsOnRealisation',
        permission: ['PROJECT_ONREALIZATION_READ'],
        component: () => import('@/views/Module2_Proekty/onRealisation/MainList.vue')
      },
      {
        path: 'projectAdd',
        name: 'ProjectAdd',
        permission: ['PROJECT_ADD'],
        component: () => import('@/views/Module2_Proekty/projects/MainAdd.vue')
      },
      {
        path: 'projectEdit',
        name: 'ProjectEdit',
        permission: ['PROJECT_EDIT'],
        component: () => import('@/views/Module2_Proekty/EditProject.vue')
      },
      {
        path: 'projectFinalize',
        name: 'ProjectFinalize',
        permission: ['PROJECT_EDIT'],
        component: () => import('@/views/Module2_Proekty/FinalizeProject.vue')
      },
      {
        path: 'projectInfo',
        name: 'ProjectInfo',
        permission: ['PROJECT_INFO'],
        component: () => import('@/views/Module2_Proekty/InfoProject.vue')
      },

      {
        path: 'planMain',
        name: 'PlanMain',
        permission: ['PER_READ'],
        component: () => import('@/views/Module3_Planirovanie/Plan/Main.vue')
      },
      {
        path: 'dohodMain',
        name: 'DohodMain',
        permission: ['PROJECT_READ'],
        component: () => import('@/views/Module3_Planirovanie/Dohod/Main.vue')
      },
      {
        path: 'rashodMain',
        name: 'RashodMain',
        permission: ['RASHOD_READ'],
        component: () => import('@/views/Module3_Planirovanie/Rashod/Main.vue')
      },
      {
        path: 'rashodAdd',
        name: 'RashodAdd',
        permission: ['RASHOD_ADD'],
        component: () => import('@/views/Module3_Planirovanie/Rashod/MainAdd.vue')
      },
      {
        path: 'rashodInfo',
        name: 'RashodInfo',
        permission: ['RASHOD_INFO'],
        component: () => import('@/views/Module3_Planirovanie/Rashod/MainInfo.vue')
      },
      {
        path: 'rashodInfoForApparat',
        name: 'RashodInfoForApparat',
        permission: ['RASHOD_INFO'],
        component: () => import('@/views/Module3_Planirovanie/Rashod/MainInfoForApparat.vue')
      },
      {
        path: 'zakupkaMain',
        name: 'ZakupkaMain',
        permission: ['ZAKUPKA_READ'],
        component: () => import('@/views/Module3_Planirovanie/Zakupka/Main.vue')
      },
      {
        path: 'zakupkaAdd',
        name: 'ZakupkaAdd',
        permission: ['ZAKUPKA_ADD'],
        component: () => import('@/views/Module3_Planirovanie/Zakupka/MainAdd.vue')
      },
      {
        path: 'zakupkaInfo',
        name: 'ZakupkaInfo',
        permission: ['ZAKUPKA_INFO'],
        component: () => import('@/views/Module3_Planirovanie/Zakupka/MainInfo.vue')
      },
      {
        path: 'zakupkaInfoForApparat',
        name: 'ZakupkaInfoForApparat',
        permission: ['ZAKUPKA_INFO'],
        component: () => import('@/views/Module3_Planirovanie/Zakupka/MainInfoForApparat.vue')
      },
      {
        path: 'zakupGroup',
        name: 'ZakupGroup',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/Module3_Planirovanie/zakupGroup/Main.vue')
      },
      {
        path: 'zakupStatia',
        name: 'ZakupStatia',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/Module3_Planirovanie/zakupStatia/Main.vue')
      },
      {
        path: 'doohdGroup',
        name: 'DohodGroup',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/Module3_Planirovanie/dohodGroup/Main.vue')
      },
      {
        path: 'dohodIst',
        name: 'DohodIst',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/Module3_Planirovanie/dohodIst/Main.vue')
      },
      {
        path: 'planAdd',
        name: 'PlanAdd',
        permission: ['PER_ADD'],
        component: () => import('@/views/Module3_Planirovanie/Plan/MainAdd.vue')
      },
      {
        path: 'planEdit',
        name: 'PlanEdit',
        permission: ['PER_EDIT'],
        component: () => import('@/views/Module3_Planirovanie/Plan/MainEdit.vue')
      },
      {
        path: 'planInfo',
        name: 'PlanInfo',
        permission: ['PER_VIEW'],
        component: () => import('@/views/Module3_Planirovanie/Plan/MainInfo.vue')
      },
      {
        path: 'rashodGroup',
        name: 'RashodGroup',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/Module3_Planirovanie/rashodGroup/Main.vue')
      },
      {
        path: 'rashodStatia',
        name: 'RashodStatia',
        permission: ['DICT_READ'],
        component: () => import('@/views/Dictionaries/Module3_Planirovanie/rashodStatia/Main.vue')
      },
      {
        path: 'dohodPlanEdit',
        name: 'DohodPlanEdit',
        permission: ['PER_DOHOD_READ'],
        component: () => import('@/views/Module3_Planirovanie/Dohod/MainAdd.vue')
      },
      {
        path: 'dohodPlanFinalize',
        name: 'DohodPlanFinalize',
        permission: ['PER_EDIT'],
        component: () => import('@/views/Module3_Planirovanie/Plan/MainFinalize.vue')
      }
    ]
  },

  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/views/Auth/SignIn')
  },
  {
    path: '/sign-out',
    name: 'SignOut',
    component: {
      beforeRouteEnter(to, from, next) {
        const destination = {
          path: '/',
          query: from.query,
          params: from.params
        }
        store.dispatch('auth/signOut')
        next(destination)
      }
    }
  },
  {
    path: '/404',
    name: 'ErrorPage',
    component: () => import('@/views/error-page/Main')
  },
  {
    path: '/403',
    name: 'ErrorPage403',
    component: () => import('@/views/error-page/Main403')
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error-page/Main')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || {left: 0, top: 0}
  }
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/workspace', '/sign-in', '/404', '/sign-out', '/403', '/sickLeaveCertificate', '/citizen', '/foreigner', '/enlisted-population', '/online-recording', '/check-talon']

  const path = to.path
  let pathNew = to.path
  const authRequired = !publicPages.includes(path)
  const loggedIn = store.state.auth.user
  pathNew = pathNew.substr(1)
  let perm = null

  let tmp = pathNew.split('/')
  tmp.pop()
  let ed = ''
  tmp.forEach(item => {
    ed += item + '/';
  })
  ed += ':id'

  if (ed === 'cetificate/validate/:id') {
    next()
  }

  routes[1].children.forEach(item => {
    if ("workspace/" + item.path === pathNew) {
      perm = item.permission
    } else {
      let tempPath = pathNew.split('/')
      tempPath.pop()
      let edited = ''
      tempPath.forEach(item => {
        edited += item + '/';
      })
      edited += ':id'

      if ("workspace/" + item.path === edited) {
        perm = item.permission
      }
    }
  })
  if (authRequired && !loggedIn) {
    next('/sign-in')
  } else if (publicPages.includes(path)) {
    if (path === '/workspace' && !loggedIn) {
      next('/sign-in')
    } else {
      next()
    }
  } else {
    if (loggedIn && store.state.auth.user.privileges != null) {
      if (path === '/') {
        next('/workspace')
      }
      const userPermissions = JSON.parse(
        JSON.stringify(store.state.auth.user.privileges)
      )
      if (checkArray(userPermissions, perm)) {
        next()
      } else {
        next({
          name: 'ErrorPage403'
        })
      }
    } else {
      next({
        name: 'ErrorPage403'
      })
    }
  }
})
const checkArray = function (userPermissions, permission) {
  let isAccess = false
  if (permission != null || permission != undefined) {
    userPermissions.forEach(item => {
      permission.forEach(item2 => {
        if (item === item2 || item === 'SUPER_ADMIN') {
          isAccess = true
        }
      })
    })
  }
  return isAccess
}


export default router
