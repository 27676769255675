import $api from "@/http";

async function getRashody(page, size, body) {
  return await $api.post('/PlanRashods/list', body, {
    params: {
      page: page,
      size: size
    }
  })
}

async function getRashodStatiyas() {
  return await $api.get('/RashodStatia')
}

async function createRashody(startDate, endDate, statusPlanirovanie, comment2) {
  return await $api.post('/PlanRashods', {
    startDate, endDate, statusPlanirovanie, comment2
  })
}

function editRashody(id, startDate, endDate, statusPlanirovanie) {
  return $api.put('/PlanRashods/' + id, {
    id, startDate, endDate, statusPlanirovanie
  })
}

async function getRashodyById(id) {
  return await $api.get('/PlanRashods/' + id)
}

async function getRashodyByOrganisationId(id, filter) {
  return await $api.post('/PlanRashods/byorgid/' + id, filter)
}

async function createRashodyDetails(body) {
  return await $api.post('/PlanRashodDetails', body)
}

async function editRashodyDetails(body) {
  return await $api.put('/PlanRashodDetails', body)
}

async function getRashodyDetailsByRashodyId(rashodId) {
  return await $api.get('/PlanRashodDetails/byRashodId', {
    params: {
      rashodId: rashodId
    }
  })
}

async function deleteRashodById(id) {
  return await $api.delete('/PlanRashods/' + id)
}

//ОДОБРИТЬ ИЛИ ОТКЛОНИТЬ
function approveOrReject(id, comment, statusPlanirovanie) {
  return $api.put('/PlanRashods/' + id, {
    id, comment, statusPlanirovanie
  })
}

export default {
  getRashody,
  getRashodStatiyas,
  createRashody,
  editRashody,
  getRashodyById,
  createRashodyDetails,
  editRashodyDetails,
  getRashodyDetailsByRashodyId,
  approveOrReject,
  deleteRashodById,
  getRashodyByOrganisationId
}
