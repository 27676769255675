import $api from '@/http'
async function getOrganisationsByPage(page, size) {
  return await $api.get('/Organisations', {
    params: {
      page: page,
      size: size
    }
  })
}
async function getOrganisations() {
  return await $api.get('/Organisations')
}

async function getOrganisationById(id) {
  return await $api.get('/Organisations/'+id)
}

async function deleteOrganisationById({id}) {
  return await $api.delete('/Organisations/'+id)
}

async function createOrganisation(formData){
  return await $api.post('/Organisations', formData)
}
async function editOrganisation(formData){
  return await $api.put('/Organisations/'+formData.id, formData)
}

async function getImushestvosByPage(orgId, page, size) {
  return await $api.get('/OrganisationImushestvos/ByOrgId/'+orgId, {
    params: {
      page: page,
      size: size
    }
  })
}
async function getImushestvos() {
  return await $api.get('/OrganisationImushestvos')
}

async function getImushestvoById(id) {
  return await $api.get('/OrganisationImushestvos/' + id)
}

async function createImushestvo(formData){
  return await $api.post('/OrganisationImushestvos', formData)
}

async function editImushestvo(formData){
  return await $api.put('/OrganisationImushestvos/' + formData.id, formData)
}

async function deleteImushestvoById({id}){
  return await $api.delete('/OrganisationImushestvos/' + id)
}

async function getArendators() {
  return await $api.get('/ArendatorImushestvos')
}

async function getArendatorById(id) {
  return await $api.get('/ArendatorImushestvos/' + id)
}

async function getArendatorByImushestvoId(id) {
  return await $api.get('/ArendatorImushestvos/ByImushestvoId/' + id)
}

async function createArendator(formData){
  return await $api.post('/ArendatorImushestvos', formData)
}

async function editArendator(formData){
  return await $api.put('/ArendatorImushestvos/' + formData.id, formData)
}

async function deleteArendator({id}){
  return await $api.delete('/ArendatorImushestvos/' + id)
}

async function getArendatorDogovors() {
  return await $api.get('/ArendaDogovorImus')
}

async function getArendatorDogovorById(id) {
  return await $api.get('/ArendaDogovorImus/' + id)
}

async function getArendatorDogovorsByArendatorId(id, { page, size}) {
  return await $api.get('/ArendaDogovorImus/arendator/' + id, {
    params: {
      page: page,
      size: size
    }
  })
}

async function createArendatorDogovor(formData){
  return await $api.post('/ArendaDogovorImus', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function editArendatorDogovor(id, formData){
  return await $api.put('/ArendaDogovorImus/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function deleteArendatorDogovor({id}){
  return await $api.delete('/ArendaDogovorImus/' + id)
}

async function getImushestvoUsers() {
  return await $api.get('/ImmushetvoUsers')
}

async function getImushestvoUsersByPage(imushestvoId, { page, size }) {
  return await $api.get('/ImmushetvoUsers/ByImushestvoId/'+imushestvoId, {
    params: {
      page: page,
      size: size
    }
  })
}

async function getImushestvoUserById(id) {
  return await $api.get('/ImmushetvoUsers/' + id)
}

async function createImushestvoUser(formData){
  return await $api.post('/ImmushetvoUsers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function editImushestvoUser(id, formData){
  return await $api.put('/ImmushetvoUsers/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function deleteImushestvoUser({id}){
  return await $api.delete('/ImmushetvoUsers/' + id)
}

export default {
  getOrganisations,
  getOrganisationById,
  deleteOrganisationById,
  getOrganisationsByPage,
  createOrganisation,
  editOrganisation,
  getImushestvosByPage,
  getImushestvos,
  createImushestvo,
  editImushestvo,
  deleteImushestvoById,
  getImushestvoById,
  getArendators,
  getArendatorById,
  getArendatorByImushestvoId,
  createArendator,
  editArendator,
  deleteArendator,
  getArendatorDogovors,
  getArendatorDogovorById,
  getArendatorDogovorsByArendatorId,
  createArendatorDogovor,
  editArendatorDogovor,
  deleteArendatorDogovor,
  getImushestvoUsers,
  getImushestvoUsersByPage,
  getImushestvoUserById,
  createImushestvoUser,
  editImushestvoUser,
  deleteImushestvoUser
}
