import $api from "@/http";

async function getProjects(page, size, body) {
  return await $api.post('/ProjectPlans/Project', body, {
    params: {
      page: page,
      size: size
    }
  })
}
async function getRealizedProjects(page, size, body) {
  return await $api.post('/ProjectPlans/Realization', body, {
    params: {
      page: page,
      size: size
    }
  })
}

async function getProjectById(id) {
  return await $api.get('ProjectPlans/' + id)
}

async function getProjectsByOrgId(page, size, orgId) {
  return await $api.get('ProjectPlans/ByOrgId/' + orgId, {
    params: {
      page: page,
      size: size
    }
  })
}

async function getLogsByProjectId(page, size, projectId) {
  return await $api.get('ProjectPlans/getLogsByPlanId/' + projectId, {
    params: {
      page: page,
      size: size
    }
  })
}

async function createProject(formData){
  return await $api.post('/ProjectPlans', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function editProject(id, formData){
  return await $api.put('/ProjectPlans/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function deleteProjectById(id) {
  return await $api.delete('ProjectPlans/' + id)
}

function approveProject(id, formData){
  return $api.put('/ProjectPlans/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

async function rejectProject(id, formData){
  return await $api.put('/ProjectPlans/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export default {
  getProjects,
  getRealizedProjects,
  getProjectById,
  getProjectsByOrgId,
  getLogsByProjectId,
  createProject,
  editProject,
  deleteProjectById,
  approveProject,
  rejectProject
}
