import $api from "@/http";

async function getDohody(page, size, body) {
  return await $api.post('/PlanDohods/list', body, {
    params: {
      page: page,
      size: size
    }
  })
}

async function getDohodyByPERId(perId) {
  return await $api.get('/PlanDohods/byPerId', {
    params: {
      perId
    }
  })
}

function editDohody(body) {
  return $api.put('/PlanDohods', body)
}

async function createDohody(body) {
  return await $api.post('/PlanDohods', body)
}

function deleteDohody(id) {
  return $api.delete('/PlanDohods/' + id)
}

export default {
  getDohody,
  getDohodyByPERId,
  editDohody,
  createDohody,
  deleteDohody
}
