import $api from "@/http";

async function getPERList(page, size, body) {
  return await $api.post('/PlanPERs/list', body, {
    params: {
      page: page,
      size: size
    }
  })
}

async function getPERById(id) {
  return await $api.get('/PlanPERs/' + id,)
}

async function createPER(formData) {
  return await $api.post('/PlanPERs', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function editPER(id, formData) {
  return await $api.put('/PlanPERs/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function deletePER(id) {
  return await $api.delete('/PlanPERs/' + id)
}

export default {
  getPERList,
  getPERById,
  createPER,
  editPER,
  deletePER,
}
