import $api from "@/http";

async function getScheduleById(id) {
  return await $api.get('PaymentShedulls/' + id)
}

async function getScheduleByProjectId(page, size, projectId) {
  return await $api.get('PaymentShedulls/ByPlanId/' + projectId, {
    params: {
      page: page,
      size: size
    }
  })
}

async function createSchedule({dateCreated, dateEnded, shedullSumm, projectPlanId}){
  return await $api.post('/PaymentShedulls', {dateCreated, dateEnded, shedullSumm, projectPlanId})
}
async function createScheduleList([{dateCreated, dateEnded, shedullSumm, projectPlanId}]){
  return await $api.post('/PaymentShedulls/list', [{dateCreated, dateEnded, shedullSumm, projectPlanId}])
}

async function editSchedule(id, {dateCreated, dateEnded, shedullSumm, projectPlanId}){
  return await $api.put('/PaymentShedulls/' + id, {id, dateCreated, dateEnded, shedullSumm, projectPlanId})
}

async function deleteScheduleById(id) {
  return await $api.delete('PaymentShedulls/' + id)
}

export default {
  getScheduleById,
  getScheduleByProjectId,
  createSchedule,
  editSchedule,
  deleteScheduleById,
  createScheduleList
}
