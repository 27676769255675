import $api from '@/http'

/** ORGANISATION TYPES START **/
async function getOrganisationTypes() {
  return await $api.get('/TypeOrganisations')
}

async function getOrganisationTypesWithPagination({page, size}) {
  return await $api.get('/TypeOrganisations', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getOrganisationTypeById({id}) {
  return await $api.get('/TypeOrganisations/' + id)
}

async function createOrganisationType({name}) {
  return await $api.post('/TypeOrganisations', {
    name
  })
}

async function editOrganisationType({id, name}) {
  return await $api.put('/TypeOrganisations/' + id, {
    id,
    name
  })
}

async function deleteOrganisationType({id}) {
  return await $api.delete('/TypeOrganisations/' + id)
}

/** ORGANISATION TYPES END **/


/** STATUSES START **/
async function getStatuses() {
  return await $api.get('/Status')
}

async function getStatusesWithPagination({page, size}) {
  return await $api.get('/Status', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getStatusById({id}) {
  return await $api.get('/Status/' + id)
}

async function createStatus({name}) {
  return await $api.post('/Status', {
    name
  })
}

async function editStatus({id, name}) {
  return await $api.put('/Status/' + id, {
    id,
    name
  })
}

async function deleteStatus({id}) {
  return await $api.delete('/Status/' + id)
}

/** STATUSES END **/


/** OTRASLS START **/
async function getOtrasls() {
  return await $api.get('/Otrasls')
}

async function getOtraslsWithPagination({page, size}) {
  return await $api.get('/Otrasls', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getOtraslById({id}) {
  return await $api.get('/Otrasls/' + id)
}

async function createOtrasl({name}) {
  return await $api.post('/Otrasls', {
    name
  })
}

async function editOtrasl({id, name}) {
  return await $api.put('/Otrasls/' + id, {
    id,
    name
  })
}

async function deleteOtrasl({id}) {
  return await $api.delete('/Otrasls/' + id)
}

/** OTRASLS END **/

/** ORGPRAVS START **/
async function getOrgPravs() {
  return await $api.get('/OrgPravs')
}

async function getOrgPravsWithPagination({page, size}) {
  return await $api.get('/OrgPravs', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getOrgPravById({id}) {
  return await $api.get('/OrgPravs/' + id)
}

async function createOrgPrav({name}) {
  return await $api.post('/OrgPravs', {
    name
  })
}

async function editOrgPrav({id, name}) {
  return await $api.put('/OrgPravs/' + id, {
    id,
    name
  })
}

async function deleteOrgPrav({id}) {
  return await $api.delete('/OrgPravs/' + id)
}

/** ORGPRAVS END **/


/** FORMASOBST START **/
async function getFormaSobsts() {
  return await $api.get('/FormaSobsts')
}

async function getFormaSobstsWithPagination({page, size}) {
  return await $api.get('/FormaSobsts', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getFormaSobstsById({id}) {
  return await $api.get('/FormaSobsts/' + id)
}

async function createFormaSobsts({name}) {
  return await $api.post('/FormaSobsts', {
    name
  })
}

async function editFormaSobsts({id, name}) {
  return await $api.put('/FormaSobsts/' + id, {
    id,
    name
  })
}

async function deleteFormaSobsts({id}) {
  return await $api.delete('/FormaSobsts/' + id)
}

/** FORMASOBST END **/

/** SOSTOYANIES START **/
async function getSostoyanies() {
  return await $api.get('/Sostoyanies')
}

async function getSostoyaniesWithPagination({page, size}) {
  return await $api.get('/Sostoyanies', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getSostoyaniesById({id}) {
  return await $api.get('/Sostoyanies/' + id)
}

async function createSostoyanies({name}) {
  return await $api.post('/Sostoyanies', {
    name
  })
}

async function editSostoyanies({id, name}) {
  return await $api.put('/Sostoyanies/' + id, {
    id,
    name
  })
}

async function deleteSostoyanies({id}) {
  return await $api.delete('/Sostoyanies/' + id)
}

/** SOSTOYANIES END **/

/** SOSTOYANIES AUTO START **/
async function getSostoyanieAutos() {
  return await $api.get('/SostoyanieAutos')
}

async function getSostoyanieAutosWithPagination({page, size}) {
  return await $api.get('/SostoyanieAutos', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getSostoyanieAutosById({id}) {
  return await $api.get('/SostoyanieAutos/' + id)
}

async function createSostoyanieAutos({name}) {
  return await $api.post('/SostoyanieAutos', {
    name
  })
}

async function editSostoyanieAutos({id, name}) {
  return await $api.put('/SostoyanieAutos/' + id, {
    id,
    name
  })
}

async function deleteSostoyanieAutos({id}) {
  return await $api.delete('/SostoyanieAutos/' + id)
}

/** SOSTOYANIES AUTO END **/


/** ZAKUP GROUP START **/
async function getZakupGroupWithPagination({page, size}) {
  return await $api.get('/ZakupGroup', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getZakupGroupById({id}) {
  return await $api.get('/ZakupGroup/' + id)
}

async function createZakupGroup({name}) {
  return await $api.post('/ZakupGroup', {
    name
  })
}

async function editZakupGroup({id, name}) {
  return await $api.put('/ZakupGroup/' + id, {
    id,
    name
  })
}

async function deleteZakupGroup({id}) {
  return await $api.delete('/ZakupGroup/' + id)
}

/** ZAKUP GROUP END **/

/** ZAKUP STATIA START **/
async function getZakupStatiaWithPagination({page, size}) {
  return await $api.get('/ZakupStatia', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getZakupStatiaById({id}) {
  return await $api.get('/ZakupStatia/' + id)
}

async function getZakupStatiasByGroupId(id) {
  return await $api.get('/ZakupStatia/ByGroupId/' + id)
}

async function createZakupStatia({name, zakupGroupId}) {
  return await $api.post('/ZakupStatia', {
    name, zakupGroupId
  })
}

async function editZakupStatia({id, name, zakupGroupId}) {
  return await $api.put('/ZakupStatia/' + id, {
    id,
    name,
    zakupGroupId
  })
}

async function deleteZakupStatia({id}) {
  return await $api.delete('/ZakupStatia/' + id)
}

/** ZAKUP STATIA END **/

/** RASHOD GROUP START **/
async function getRashodGroupWithPagination({page, size}) {
  return await $api.get('/RashodGroup', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getRashodGroupById({id}) {
  return await $api.get('/RashodGroup/' + id)
}

async function createRashodGroup({name}) {
  return await $api.post('/RashodGroup', {
    name
  })
}

async function editRashodGroup({id, name}) {
  return await $api.put('/RashodGroup/' + id, {
    id,
    name
  })
}

async function deleteRashodGroup({id}) {
  return await $api.delete('/RashodGroup/' + id)
}

/** RASHOD GROUP END **/

/** RASHOD STATIA START **/
async function getRashodStatiaWithPagination({page, size}) {
  return await $api.get('/RashodStatia', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getRashodStatiaById({id}) {
  return await $api.get('/RashodStatia/' + id)
}

async function getRashodStatiasByGroupId(id) {
  return await $api.get('/RashodStatia/ByGroupId/' + id)
}

async function createRashodStatia({name, rashodGroupId}) {
  return await $api.post('/RashodStatia', {
    name, rashodGroupId
  })
}

async function editRashodStatia({id, name, rashodGroupId}) {
  return await $api.put('/RashodStatia/' + id, {
    id,
    name,
    rashodGroupId
  })
}

async function deleteRashodStatia({id}) {
  return await $api.delete('/RashodStatia/' + id)
}

/** RASHOD STATIA END **/


/** DOHOD GROUP START **/
async function getDohodGroupWithPagination({page, size}) {
  return await $api.get('/DohodGroup', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getDohodGroupList() {
  let list = []
  await $api.get('/DohodGroup').then(
    (response) => {
      list = response.data.result
    }
  )
  return list
}

async function getDohodGroupById({id}) {
  return await $api.get('/DohodGroup/' + id)
}

async function createDohodGroup({name}) {
  return await $api.post('/DohodGroup', {
    name
  })
}

async function editDohodGroup({id, name}) {
  return await $api.put('/DohodGroup/' + id, {
    id,
    name
  })
}

async function deleteDohodGroup({id}) {
  return await $api.delete('/DohodGroup/' + id)
}

/** DOHOD GROUP END **/

/** DOHOD START **/
async function getDohodWithPagination({page, size}) {
  return await $api.get('/DohodIst', {
    params: {
      page: page,
      size: size
    }
  })
}

async function getDohodList() {
  let list = []
  await $api.get('/DohodIst').then(
    (response) => {
      list = response.data.result
    }
  )
  return list
}

async function getDohodById({id}) {
  return await $api.get('/DohodIst/' + id)
}

async function createDohod({name, dohodGroupId}) {
  return await $api.post('/DohodIst', {
    name,
    dohodGroupId,
  })
}

async function editDohod({id, name, dohodGroupId}) {
  return await $api.put('/DohodIst/' + id, {
    id,
    name,
    dohodGroupId,
  })
}

async function deleteDohod({id}) {
  return await $api.delete('/DohodIst/' + id)
}

async function getDohodByGroupId({id}) {
  return await $api.get('/DohodIst/groupId/' + id)
}

/** DOHOD END **/

let genResult = {
  currentPage: null,
  pageSize: null,
  totalPages: null,
  totalItems: null,
  result: [],
  pagenumbersStart: null,
  pagenumbersEnd: null
}


export default {
  getOrganisationTypes,
  getOrganisationTypesWithPagination,
  getOrganisationTypeById,
  createOrganisationType,
  editOrganisationType,
  deleteOrganisationType,
  getStatuses,
  getStatusesWithPagination,
  getStatusById,
  createStatus,
  editStatus,
  deleteStatus,
  getOtrasls,
  getOtraslsWithPagination,
  getOtraslById,
  createOtrasl,
  editOtrasl,
  deleteOtrasl,
  getOrgPravs,
  getOrgPravsWithPagination,
  getOrgPravById,
  createOrgPrav,
  editOrgPrav,
  deleteOrgPrav,
  getFormaSobsts,
  getFormaSobstsWithPagination,
  getFormaSobstsById,
  createFormaSobsts,
  editFormaSobsts,
  deleteFormaSobsts,
  getSostoyanies,
  getSostoyaniesWithPagination,
  getSostoyaniesById,
  createSostoyanies,
  editSostoyanies,
  deleteSostoyanies,
  getSostoyanieAutos,
  getSostoyanieAutosWithPagination,
  getSostoyanieAutosById,
  createSostoyanieAutos,
  editSostoyanieAutos,
  deleteSostoyanieAutos,
  getZakupGroupWithPagination,
  getZakupGroupById,
  createZakupGroup,
  editZakupGroup,
  deleteZakupGroup,
  getZakupStatiaWithPagination,
  getZakupStatiaById,
  createZakupStatia,
  editZakupStatia,
  getRashodGroupWithPagination,
  getRashodGroupById,
  createRashodGroup,
  editRashodGroup,
  deleteRashodGroup,
  getRashodStatiaWithPagination,
  getRashodStatiaById,
  createRashodStatia,
  editRashodStatia,
  deleteRashodStatia,
  getDohodGroupWithPagination,
  getDohodGroupList,
  getDohodGroupById,
  createDohodGroup,
  editDohodGroup,
  deleteDohodGroup,
  getDohodWithPagination,
  getDohodList,
  getDohodById,
  createDohod,
  editDohod,
  deleteDohod,
  getDohodByGroupId,
  deleteZakupStatia,
  getRashodStatiasByGroupId,
  getZakupStatiasByGroupId
}


