import $api from '@/http'
async function getTransportsByPage(orgId, page, size) {
  return await $api.get('/OrganisationTransports/ByOrgId/'+orgId, {
    params: {
      page: page,
      size: size
    }
  })
}
async function getTransports() {
  return await $api.get('/OrganisationTransports')
}

async function getTransportById(id) {
  return await $api.get('/OrganisationTransports/' + id)
}

async function createTransport(formData){
  return await $api.post('/OrganisationTransports', formData)
}

async function editTransport(formData){
  return await $api.put('/OrganisationTransports/' + formData.id, formData)
}

async function deleteTransportById({id}){
  return await $api.delete('/OrganisationTransports/' + id)
}


export default {
  getTransportsByPage,
  getTransports,
  createTransport,
  editTransport,
  getTransportById,
  deleteTransportById,
}
