import $api from "@/http";

async function getZakupkis(page, size, body) {
  return await $api.post('/PlanZakupkis/list', body, {
    params: {
      page: page,
      size: size
    }
  })
}
async function getZakupkiStatiyas() {
  return await $api.get('/ZakupStatia')
}

async function createZakupka(startDate, endDate, statusPlanirovanie, comment2) {
  return await $api.post('/PlanZakupkis', {
    startDate, endDate, statusPlanirovanie, comment2
  })
}

function editZakupka(id, startDate, endDate, statusPlanirovanie) {
  return $api.put('/PlanZakupkis/' + id, {
    id, startDate, endDate, statusPlanirovanie
  })
}

async function getZakupkaById(id) {
  return await $api.get('/PlanZakupkis/' + id)
}

async function getZakupkaByOrganisationId(id, filter) {
  return await $api.post('/PlanZakupkis/byorgid/' + id,filter)
}

async function createZakupkaDetails(body) {
  return await $api.post('/PlanZakupkiDetails', body)
}

async function editZakupkaDetails(body) {
  return await $api.put('/PlanZakupkiDetails', body)
}

async function getZakupkaDetailsByZakupkaId(zakupId) {
  return await $api.get('/PlanZakupkiDetails/byZakupId', {
    params: {
      zakupId: zakupId
    }
  })
}

async function deleteZakupkaById(id) {
  return await $api.delete('/PlanZakupkis/' + id)
}

//ОДОБРИТЬ ИЛИ ОТКЛОНИТЬ
function approveOrReject(id, comment, statusPlanirovanie) {
  return $api.put('/PlanZakupkis/' + id, {
    id, comment, statusPlanirovanie
  })
}

export default {
  getZakupkis,
  getZakupkiStatiyas,
  createZakupka,
  createZakupkaDetails,
  getZakupkaById,
  editZakupka,
  editZakupkaDetails,
  getZakupkaDetailsByZakupkaId,
  approveOrReject,
  deleteZakupkaById,
  getZakupkaByOrganisationId
}
