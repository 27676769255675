import $api from '@/http'

function getOrganisationListForMap({ oblastId, typeOrg}) {
  return $api.get('/ForMap', {
    params: {
      oblastId: oblastId != null ? oblastId : null,
      typeOrg: typeOrg != null ? typeOrg : null
    }
  })
}

function getDetails({ id, org}) {
  return $api.get('/ForMap/Details', {
    params: {
      id: id != null ? id : null,
      org: org != null ? org : null
    }
  })
}

function getInfoAboutZemlya(id) {
  return $api.get('/OrganisationZemlyas/' + id, {
    params: {
      id: id != null ? id : null
    }
  })
}

export default {
  getOrganisationListForMap,
  getDetails,
  getInfoAboutZemlya
}
