import $api from '@/http'
async function getZemlyasByPage(orgId, page, size) {
  return await $api.get('/OrganisationZemlyas/ByOrgId/'+orgId, {
    params: {
      page: page,
      size: size
    }
  })
}
async function getZemlyas() {
  return await $api.get('/OrganisationZemlyas')
}

async function getZemlyaById(id) {
  return await $api.get('/OrganisationZemlyas/' + id)
}

async function createZemlya(formData){
  return await $api.post('/OrganisationZemlyas', formData)
}

async function editZemlya(formData){
  return await $api.put('/OrganisationZemlyas/' + formData.id, formData)
}

async function deleteZemlya({id}){
  return await $api.delete('/OrganisationZemlyas/' + id)
}

/**
 *
 */

async function getArendators() {
  return await $api.get('/ArendatorZemlyas')
}

async function getArendatorById(id) {
  return await $api.get('/ArendatorZemlyas/' + id)
}

async function getArendatorByZemlyaId(id) {
  return await $api.get('/ArendatorZemlyas/ByZemlyaId/' + id)
}

async function createArendator(formData){
  return await $api.post('/ArendatorZemlyas', formData)
}

async function editArendator(formData){
  return await $api.put('/ArendatorZemlyas/' + formData.id, formData)
}

async function deleteArendator({id}){
  return await $api.delete('/ArendatorZemlyas/' + id)
}

async function getArendatorDogovors() {
  return await $api.get('/ArendaDogovorZemlyas')
}

async function getArendatorDogovorById(id) {
  return await $api.get('/ArendaDogovorZemlyas/' + id)
}

async function getArendatorDogovorsByArendatorId(id, { page, size}) {
  return await $api.get('/ArendaDogovorZemlyas/arendator/' + id, {
    params: {
      page: page,
      size: size
    }
  })
}

async function createArendatorDogovor(formData){
  return await $api.post('/ArendaDogovorZemlyas', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function editArendatorDogovor(id, formData){
  return await $api.put('/ArendaDogovorZemlyas/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function deleteArendatorDogovor({id}){
  return await $api.delete('/ArendaDogovorZemlyas/' + id)
}

async function getZemlyaUsers() {
  return await $api.get('/ZemlyaUsers')
}

async function getZemlyaUsersByPage(imushestvoId, { page, size }) {
  return await $api.get('/ZemlyaUsers/ByZemlyaId/'+imushestvoId, {
    params: {
      page: page,
      size: size
    }
  })
}

async function getZemlyaUserById(id) {
  return await $api.get('/ZemlyaUsers/' + id)
}

async function createZemlyaUser(formData){
  return await $api.post('/ZemlyaUsers', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function editZemlyaUser(id, formData){
  return await $api.put('/ZemlyaUsers/' + id, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  })
}

async function deleteZemlyaUser({id}){
  return await $api.delete('/ZemlyaUsers/' + id)
}

export default {
  getArendatorDogovorById,
  getArendatorDogovors,
  deleteArendator,
  editArendator,
  createArendator,
  getArendatorByZemlyaId,
  getArendatorById,
  getArendators,
  getZemlyasByPage,
  getZemlyas,
  createZemlya,
  editZemlya,
  deleteZemlya,
  getZemlyaById,
  deleteZemlyaUser,
  editZemlyaUser,
  createZemlyaUser,
  getZemlyaUserById,
  getZemlyaUsersByPage,
  getZemlyaUsers,
  deleteArendatorDogovor,
  editArendatorDogovor,
  createArendatorDogovor,
  getArendatorDogovorsByArendatorId
}
